<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>出库单管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col style="width: 140px">
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="companyId" :options="companys" clearable @change="fetchs" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" clearable
              check-strictly :render-after-expand="false" style="min-width: 210px;" />
          </el-col>
        </el-form-item>

        <el-form-item label="单据时间">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="入库类型" prop="billType">
          <el-col>
            <el-select v-model="billType" placeholder="请选择入库类型" clearable @change="fetchs" style="width: 150px">
              <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="入库状态" prop="status">
          <el-col>
            <el-select v-model="status" placeholder="请选择入库状态" @change="fetchs" clearable style="width: 150px">
              <el-option key="待审核" label="待审核" value="0"></el-option>
              <el-option key="已审核" label="已审核" value="1"></el-option>
              <el-option key="已取消" label="已取消" value="-1"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="Search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="Plus" @click="openInStock">
        操作入库
      </el-button>
      <el-button plain icon="Download" @click="exportXls">
        导出单据
      </el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">

      <template v-slot:status="scope">
        <el-link v-if="scope.row.status == 0" type="primary" size="small" disabled>待审核</el-link>
        <el-link v-else-if="scope.row.status == 1" type="success" size="small" disabled>已审核</el-link>
        <el-link v-else-if="scope.row.status == -1" type="danger" size="small" disabled>已取消</el-link>
        <el-link v-else type="info" size="small" disabled>其他</el-link>
      </template>

      <template v-slot:toolbar="scope">
        <el-link @click="watch(scope.row)" type="primary" size="small">查看</el-link>

        <el-link @click="delRow(scope.row)" type="primary" size="small" v-if="scope.row.status == 0"
          style="margin-left:10px;">删除</el-link>
      </template>
    </static-table>
  </el-card>

  <!-- <static-dialog ref="instoredialog" class="dialog" title="预入库单" width="80%" :ok="submitInStock" :close="closeInstock">
    <instore ref="instore" @freshen="fetchs()"></instore>
  </static-dialog> -->
</template>

<script>
import { fetchBills, delBillInfo, exportBill } from "@/api/stock";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import instore from "./instore.vue";
export default {
  name: "billInList",
  components: { StaticDialog, StaticTable, instore },
  data() {
    return {
      dateRange: [],

      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "selection", width: "55" },
          { type: "index", label: "序号" },
          { prop: "billType", label: "单据类型", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "billCode", label: "单据编号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { prop: "storeCode", label: "库房", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "netCode", label: "关联单号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "productNumber", label: "数量", sortable: true, searchable: true, width: "70", },
          { prop: "costTotal", label: "金额", sortable: true, width: "90", },
          { prop: "billDate", label: "单据时间", width: "140", sortable: true, showOverflowTooltip: true },
          { type: "template", prop: "status", label: "状态", width: "60", searchable: true, },
          { prop: "operatorName", label: "操作", sortable: true, searchable: true, width: "70", },
          { prop: "handoverName", label: "交接", sortable: true, searchable: true, width: "70", },
          { prop: "reviewerName", label: "复核", sortable: true, searchable: true, width: "70", },
          { prop: "financerName", label: "财务", sortable: true, searchable: true, width: "70", },

          { prop: "createDate", label: "创建时间", width: "150", sortable: true, showOverflowTooltip: true },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "80", },
          { type: "toolbar", label: "操作", width: "140" },
        ],
      },
      pager: {
        sidx: "billDate", //默认的排序字段
        sord: "descending",
      },
      selects: [],
      currRow: null,

      status: '',
      companys: [],
      companyId: '',
      billType: '',
      dict: {
        billTypes: [],
      },
    };
  },
  mounted() {
    this.$kaung.dictionary("stock", "inbound").then((res) => { this.dict.billTypes = res; });

    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.fetchs();
    });
  },
  methods: {
    watch(row) {
      this.$router.push({ path: "indetail", query: { billCode: row.billCode } });
    },

    fetchs() {
      this.querys.filters = [];
      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "billDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "billDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.companyId && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }

      if (this.billType) {
        this.querys.filters.push({
          name: "billType",
          value: this.billType,
          operate: 0,
        });
      }
      else {
        let billTypes=[];
        this.dict.billTypes.forEach((res)=>{billTypes.push(res.value)});
        this.querys.filters.push({
          name: "billType",
          value: billTypes.join(','),
          operate: 6,
        });
      }

      if (this.status) {
        this.querys.filters.push({
          name: "status",
          value: this.status,
          operate: 0,
        });
      }

      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      fetchBills(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },

    openInStock() {
      //this.$refs.instoredialog.toggle();
      this.$router.push("instore")
    },
    submitInStock() {
      this.$refs.instore.save();
    },
    closeInstock() {
      this.$refs.instore.clear();
    },
    delRow(row) {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.status == 1) {
            that.$message({
              type: "info",
              message: "删除失败，已审核单据不允许删除",
            });
            return;
          }

          delBillInfo(row.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    exportXls() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      this.selects.forEach(element => {
        exportBill(element.billCode).then((res) => {
          if (res.code == 200)
            window.location.href = process.env.VUE_APP_BASEURL + res.data;
        });
      });
    }
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    },
    billType: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('billType', newValue);
    },
    status: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('status', newValue);
    },
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);
    }
  },
  created: function () {
    let companyIdStr = this.$util.getLocalstorage('companyIdStr');
    if (companyIdStr != '' && (this.companyId == null || this.companyId.length == 0)) {
      this.companyId = companyIdStr;
    }
    this.querys.keyword = this.$util.getPageCache('keyword');
    this.billType = this.$util.getPageCache('billType') || "";
    this.status = this.$util.getPageCache('status');

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;
  }
};
</script>

<style lang="less"></style>>
